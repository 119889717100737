import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class AccountService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listAccount (filters) {
		const response = await this.axios.get("account/listAccount", { params: { filters } });
		return response.data;
	}

	async upsertAccount (payload) {
		const response = await this.axios.post("account/upsertAccount", payload);
		return response.data;
	}

	async deleteAccount (id) {
		const response = await this.axios.delete("account/deleteAccount", { params: id });
		return response.data;
	}
}
